import * as React from "react"
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import PageHead from "../components/PageHead";
const IndexPage = () => (
  <Layout >
    <Seo title="About" />
    <PageHead title="Hello! I'm Prabhdip Gill" description="Software Engineer"/>
    <Container fluid   >
      <Row className='center'>
        <Col md={6} className=''>
          <StaticImage src='../images/PFP.jpg' width='175' loading='lazy'/>
        
        </Col>

        <Col md={6} className='mt-5 fs-5' >
        <h2> About me</h2>
          <p > I graduated from the  California State University, Sacramento in 2021 with a B.S in Computer Science.  Most of my professional experience has been in
          full stack development and I love to work on new and challenging projects.
        </p></Col>
        </Row>
        <div style={{textAlign:'left'}}>
        <h2 > Experience</h2>
        <h5 className='mt-3'>Software Developer Intern, Georgia Tech Research Institute</h5>
        <p className='fs-5'>May 2020 – December 2020</p>

        <h5 className='mt-5'>Application Developer Intern, CALPIA</h5>
        <p className='fs-5'>June 2019 – January 2020</p>
        </div>


        
  </Container>


  </Layout>
)

export default IndexPage
